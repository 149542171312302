import verConfig, { ver as _ver_ } from "@tt/config";
export const ver = _ver_;
// export const ver = 'A6405';

export const verData = {
  "A6416": {
    tabbar: {
      background: "#fff",
      selectedColor: "#00a6fe",
      color: "#6c7b9d",
      type: "image",
      pages: [
        {
          text: 'newTab.home',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          iconPath: require("@/assets/tabbar/home.png"),
          selectedIconPath: require("@/assets/tabbar/home_active.png"),
          isSpecial: false,
        },
        {
          text: `${'newTab.order'}`,
          pagePath: 'cart',
          component: () => import('@/view/cart'),
          iconPath: require("@/assets/tabbar/record.png"),
          selectedIconPath: require("@/assets/tabbar/record_active.png"),
          isSpecial: false,
        },
        {
          text: `${'page.games'}`,
          pagePath: 'Merchants',
          component: () => import('@/view/new/merchants'),
          iconPath: require("@/assets/tabbar/game.png"),
          selectedIconPath: require("@/assets/tabbar/game_active.png"),
          // isSpecial: true,
        },
        {
          text: `tab.activity`,
          pagePath: 'activity',
          component: () => import('@/view/new/activity'),
          iconPath: require("@/assets/tabbar/prize.png"),
          selectedIconPath: require("@/assets/tabbar/prize_active.png"),
          isSpecial: false,
        },
        {
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          iconPath: require("@/assets/tabbar/mine.png"),
          selectedIconPath: require("@/assets/tabbar/mine_active.png"),
          isSpecial: false,
        },
      ]
    }
  },
  "A6416_test": {
    tabbar: {
      background: "#fff",
      selectedColor: "#00a6fe",
      color: "#6c7b9d",
      type: "image",
      pages: [
        {
          text: 'newTab.home',
          pagePath: 'goods',
          component: () => import("@/view/goods"),
          iconPath: require("@/assets/tabbar/home.png"),
          selectedIconPath: require("@/assets/tabbar/home_active.png"),
          isSpecial: false,
        },
        {
          text: `${'newTab.order'}`,
          pagePath: 'cart',
          component: () => import('@/view/cart'),
          iconPath: require("@/assets/tabbar/record.png"),
          selectedIconPath: require("@/assets/tabbar/record_active.png"),
          isSpecial: false,
        },
        {
          text: `${'page.games'}`,
          pagePath: 'Merchants',
          component: () => import('@/view/new/merchants'),
          iconPath: require("@/assets/tabbar/game.png"),
          selectedIconPath: require("@/assets/tabbar/game_active.png"),
          // isSpecial: true,
        },
        {
          text: `tab.activity`,
          pagePath: 'activity',
          component: () => import('@/view/new/activity'),
          iconPath: require("@/assets/tabbar/prize.png"),
          selectedIconPath: require("@/assets/tabbar/prize_active.png"),
          isSpecial: false,
        },
        {
          text: `${'newTab.my'}`,
          pagePath: 'user',
          component: () => import('@/view/user'),
          iconPath: require("@/assets/tabbar/mine.png"),
          selectedIconPath: require("@/assets/tabbar/mine_active.png"),
          isSpecial: false,
        },
      ]
    }
  }
}

export default {
  ...verConfig, ...verData[ver]
};