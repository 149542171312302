export const themes = {
  light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#fff",
    '--navBg': "#fff",
    '--navColor': '#333',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  },
  a6406Light: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#e4f0fa",
    '--tarbarBg': "#2a2b35",
    '--navBg': "#cd0000",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#6a82ad",
    '--gray3':'#b8ecff',
    '--theme': "#00bbfe",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#fff',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)',
    '--moduleColor': '#06314d',
    '--moduleSizeColor': '#00bbfe',
    '--btnsizeColor': '#fff',
    '--gameSizeColor': '#b8ecff',
    '--hallSizecolor': '#fff7b7',
    '--hallBg': '#0d192c',
    '--gameBg': '#07304a',
    '--valueColor':'#fea700'
  },
  heartLight: {
    '--textColor': "#333",
    '--light': '#fff',
    '--bg': "#FCFCFC",
    '--tarbarBg': "#fff",
    '--navBg': "#EB6B8C",
    '--navColor': '#fff',
    '--gray1': "#999",
    '--gray2': "#666",
    '--theme': "#EB6B8C",
    '--darkTheme': "#bcddfa",
    '--tarbarColor': '#6c7b9d',
    '--tarbarActiveColor': '#00a6fe',
    '--theme1': "#5c7891",
    '--bg1': '#fff',
    '--bg2': "#f5f5f5",
    '--borderColor': 'rgba(0, 0, 0, .2)'
  }
}
